
import { Component, Vue } from 'vue-property-decorator'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import ColumnTree from '@/components/ColumnTree/ColumnTree.vue'
import { TreeItem } from '@/interfaces/TreeItem.interface'
import { BasicTreeItem } from '@/interfaces/BasicTreeItem.interface'
import ItemPicker from '@/components/ItemPicker/ItemPicker.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Dashboard-View.
 */
@Component({
  components: {
    CustomDialog,
    ColumnTree,
    ItemPicker,
    BaseFrame
  }
})
export default class SpielwieseBaum extends Vue {
  /**
   * columnTreeData
   */
  public columnTreeData: TreeItem[] = []

  /**
   * Aktuelle ID, die für [[generateTree]] verwendet wird (für Testzwecke).
   */
  public id = 0

  /**
   * columnWidth
   */
  public columnWidth = 300

  /**
   * dialogOpen
   */
  public dialogOpen = false

  /**
   * created
   */
  public created(): void {
    this.columnTreeData = this.generateExampleData()
  }

  /**
   * Generiert eine zufällige Ganzzahl (für Testzwecke).
   *
   * @param min - Kleinstmögliche Zahl.
   * @param max - Größtmögliche Zahl.
   * @returns Zufällige Ganzzahl.
   */
  public random(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  /**
   * Gibt ein zufällig gewähltes Element aus einem gegebenen Array zurück.
   *
   * @param elements - Array mit Elementen.
   * @returns Zufällig gewähltes Element.
   */
  public randomFrom<T>(...elements: T[]): T {
    return elements[Math.floor(elements.length * Math.random())]
  }

  /**
   * Erstellt einen Baum mit Zufallsdaten (für Testzwecke).
   *
   * @param depth - Aktuelle Tiefe der zu generierenden Ebene.
   * @returns Baumstruktur.
   */
  public generateTree(depth = 0): TreeItem[] {
    const size = this.random(1, 20)
    const data: TreeItem[] = []

    for (let i = 0; i < size; ++i) {
      const item: TreeItem = {
        id: ++this.id,
        title: this.randomFrom<string>(
          'Banana',
          'Cooljan',
          'Swagbart',
          'Yetaki',
          'Belastend',
          'Gunkabell',
          'Tanklaster',
          'Junge',
          'Ninja',
          'Flex'
        ),
        children: [],
        icon: this.randomFrom<string>(
          'mdi-angle-right',
          'mdi-apple-keyboard-command',
          'mdi-boom-gate-outline',
          'mdi-car-convertible',
          'mdi-chart-bar-stacked',
          'mdi-clock-out',
          'mdi-concourse-ci',
          'mdi-currency-sign',
          'mdi-diameter-variant',
          'mdi-elevation-decline',
          'mdi-file-move',
          'mdi-folder-key'
        )
      }

      if (depth < 5 && this.randomFrom<boolean>(false, false, true)) {
        item.children = this.generateTree(depth + 1)
      }

      data.push(item)
    }

    return data
  }

  /**
   * Array ausgewählter Elemente.
   */
  public selectedItems: BasicTreeItem[] = []

  /**
   * [selectedItemsLocations description]
   */
  public selectedItemsProperties: BasicTreeItem[] = []

  /**
   * [selectedItemsLocations description]
   */
  public selectedItemsPicker: BasicTreeItem[] = [{ id: 10, title: 'Mkey Isl' }]

  /**
   * Generiert eine zufällige Baumstruktur für <column-tree>.
   *
   * @returns Baumstruktur.
   */
  public generateExampleData(): TreeItem[] {
    return [
      {
        id: 0,
        title: '',
        children: this.generateTree()
      }
    ]
  }

  /**
   * Event-Handler für einen Klick auf das "x" bei einem ausgewählten Ort im
   * Dummy-Picker. Wird momentan nicht verwendet.
   *
   * @param item - Zu deselektierender Eintrag.
   */
  public deselect(item: BasicTreeItem): void {
    this.selectedItems.splice(this.selectedItems.indexOf(item), 1)
  }
}
